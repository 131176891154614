<template>
  <div class='home'>
    <v-container>
      <FideicomisoLista></FideicomisoLista>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import FideicomisoLista from '@/components/FideicomisoLista.vue';
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'Home',
  components: {
    FideicomisoLista,
  },
  methods: {
    redireccion() {
      const token = localStorage.getItem('token');
      if (token !== 'undefined' && token !== null && token !== '') {
        axios
          .post(`${API_URL}api/usuarios/validarToken`, {
            token: localStorage.getItem('token'),
          })
          .then(() => {
            // Token válido
          })
          .catch(() => {
            this.$router.push('/login');
          });
      } else {
        this.$router.push('/login');
      }
    },
  },
  created() {
    //  this.redireccion();
  },
};
</script>
